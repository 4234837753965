import React, {useState, useEffect, useRef} from 'react'
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js'
import { Bar, getElementsAtEvent } from 'react-chartjs-2'
import { Colors } from 'chart.js'
import {useQuery, gql} from '@apollo/client'
import ChartDetal from './ChartDetal'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors)
ChartJS.defaults.backgroundColor = '#00000010'
ChartJS.defaults.borderColor = '#23497680'
ChartJS.defaults.color = 'rgba(255,255,255,.8)'
ChartJS.defaults.font.size = 13
ChartJS.defaults.font.family = '"Podkova", serif'
ChartJS.defaults.elements.bar.borderWidth = 2

 
export default function Chart() {
const labels = ['Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек', 'Янв']
const GET_CHART = gql`
      query {
        tickets {
          user
          created
        }
        users {
          login
          created
        }
      }
`;

const chartData = {
  labels,
  datasets: [
    {
      id: 1,
      label: 'Пользователи',
      data: [],
      backgroundColor: 'rgba(248,128,33,.8)',
      borderColor: 'rgba(248,128,33,1)'
    },
    {
      id: 2,
      label: 'Билеты',
      data: [],
      backgroundColor: 'rgba(5,255,81,.7)',
      borderColor: 'rgba(5,255,81,1)'
    },
  ],
}
  const chartRef = useRef()
  const onBarClick = (event) => {
    let barIndex
    try{
      barIndex = getElementsAtEvent(chartRef.current, event)[0].index
      const barDetail = dataTickets[barIndex] + dataTickets[barIndex]
    //  console.log(listTickets[barIndex])
      setShowDetail(true)
      setDetailList(listTickets[barIndex])
    }catch (err){
      return
    }
  }

  const yearNow = (new Date()).getFullYear()
  const years = new Set()
  const [showDetail, setShowDetail] = useState(false)
  const [detailList, setDetailList] = useState([])
  const [selectedYear, setSelectedYear] = useState(yearNow)
  const {data, loading, error} = useQuery(GET_CHART)
//  useEffect(() => {}, [selectedYear])
  if (loading) return <div className="loading" />
  if (error) return <pre>{error.message}</pre>
  const listTickets = [[], [], [], [], [], [], [], [], [], [], [], []]
  const dataTickets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  const dataUsers   = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  let t_j = 0, t_f = 0, t_m = 0, t_a = 0, t_my = 0, t_jn = 0, t_jl = 0, t_ag = 0, t_s = 0, t_o = 0, t_n = 0, t_d = 0,
      u_j = 0, u_f = 0, u_m = 0, u_a = 0, u_my = 0, u_jn = 0, u_jl = 0, u_ag = 0, u_s = 0, u_o = 0, u_n = 0, u_d = 0

  data.tickets.map(item => {
      let year = (new Date(item.created)).getFullYear()
      years.add(year)
      if(year === selectedYear){
        let month = (new Date(item.created)).getMonth()
        if(month === 1){
          ++t_j
          listTickets[0].push(["ticket", item.created, item.user])
        }
        else if(month === 2){
          ++t_f
          listTickets[1].push(["ticket", item.created, item.user])
        }
        else if(month === 3){
          ++t_m
          listTickets[2].push(["ticket", item.created, item.user])
        }
        else if(month === 4){
          ++t_a
          listTickets[3].push(["ticket", item.created, item.user])
        }
        else if(month === 5){
          ++t_my
          listTickets[4].push(["ticket", item.created, item.user])
        }
        else if(month === 6){
          ++t_jn
          listTickets[5].push(["ticket", item.created, item.user])
        }
        else if(month === 7){
          ++t_jl
          listTickets[6].push(["ticket", item.created, item.user])
        }
        else if(month === 8){
          ++t_ag
          listTickets[7].push(["ticket", item.created, item.user])
        }
        else if(month === 9){
          ++t_s
          listTickets[8].push(["ticket", item.created, item.user])
        }
        else if(month === 10){
          ++t_o
          listTickets[9].push(["ticket", item.created, item.user])
        }
        else if(month === 11){
          ++t_n
          listTickets[10].push(["ticket", item.created, item.user])
        }
        else if(month === 12){
          ++t_d
          listTickets[11].push(["ticket", item.created, item.user])
        }
      }   
  })
  
  dataTickets[0] = t_j
  dataTickets[1] = t_f
  dataTickets[2] = t_m
  dataTickets[3] = t_a
  dataTickets[4] = t_my
  dataTickets[5] = t_jn
  dataTickets[6] = t_jl
  dataTickets[7] = t_ag
  dataTickets[8] = t_s
  dataTickets[9] = t_o
  dataTickets[10] = t_n
  dataTickets[11] = t_d

  data.users.map(item => {
    let year = (new Date(item.created)).getFullYear()
    years.add(year)
    if(year === selectedYear){
        let month = (new Date(item.created)).getMonth()
        if(month === 1){
          ++u_j
          listTickets[0].push(["user", item.created, item.login])
        }
        else if(month === 2){
          ++u_f
          listTickets[1].push(["user", item.created, item.login])
        }
        else if(month === 3){
          ++u_m
          listTickets[2].push(["user", item.created, item.login])
        }
        else if(month === 4){
          ++u_a
          listTickets[3].push(["user", item.created, item.login])
        }
        else if(month === 5){
          ++u_my
          listTickets[4].push(["user", item.created, item.login])
        }
        else if(month === 6){
          ++u_jn
          listTickets[5].push(["user", item.created, item.login])
        }
        else if(month === 7){
          ++u_jl
          listTickets[6].push(["user", item.created, item.login])
        }
        else if(month === 8){
          ++u_ag
          listTickets[7].push(["user", item.created, item.login])
        }
        else if(month === 9){
          ++u_s
          listTickets[8].push(["user", item.created, item.login])
        }
        else if(month === 10){
          ++u_o
          listTickets[9].push(["user", item.created, item.login])
        }
        else if(month === 11){
          ++u_n
          listTickets[10].push(["user", item.created, item.login])
        }
        else if(month === 12){
          ++u_d
          listTickets[11].push(["user", item.created, item.login])
        }
    }   
})

dataUsers[0] = u_j
dataUsers[1] = u_f
dataUsers[2] = u_m
dataUsers[3] = u_a
dataUsers[4] = u_my
dataUsers[5] = u_jn
dataUsers[6] = u_jl
dataUsers[7] = u_ag
dataUsers[8] = u_s
dataUsers[9] = u_o
dataUsers[10] = u_n
dataUsers[11] = u_d

chartData.datasets[0].data = dataUsers
chartData.datasets[1].data = dataTickets

// console.log(listTickets)

const dataMax = Math.max.apply(null, dataUsers.concat(dataTickets))

const options = {
  responsive: true,
  layout: {padding: 30},
  scales: {
    y: {type: 'linear',min: 0,max: Math.round(dataMax + dataMax * 0.1)}
  },
  plugins: {
    colors: {enabled: false, forceOverride: true},
    legend: {position: 'bottom'},
    title: {display: true,text: ''}
  }
}

function onYearClick(item){
  setSelectedYear(item)
  setShowDetail(false)
}

 return (
    <div id="chart">
      <ul class="years">
      {Array.from(years).sort().map((item, index) => (
          <li key={index} className={item === selectedYear && 'active'} onClick={() => onYearClick(item)}>{item}</li>
      ))}
      </ul>
      {!showDetail && <Bar ref={chartRef} onClick={onBarClick} options={options} data={chartData} />}
      {showDetail && <ChartDetal data={detailList} />}
      {showDetail && <button className="btn-detail" onClick={() => setShowDetail(false)}>Вернуть график</button>}
    </div>
 )
}
