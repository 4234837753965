import React, {memo} from 'react'
import Media from 'react-media'
import { useNavigate } from 'react-router-dom'
import {useQuery, gql} from '@apollo/client'
import {format} from 'date-fns'
import LightSpeed from 'react-reveal/LightSpeed'

import {ReactComponent as PaysSvg} from '../lib/svg/pays.svg'
import {ReactComponent as PlusSvg} from '../lib/svg/plus.svg'

const GET_PAYS = gql`
      query Pay {
        pays {
          id
          created
          type
          user
          amount
          login {
            isOnline
          }
        }
      }`;

const PaysTbl = () => {
    const {data, loading, error} = useQuery(GET_PAYS)
    const login = window.location.href.split('/')[5]
    const loadUser = useNavigate()
    
    if (loading) return <div className="loading" />
    if (error) return <pre>{error.message}</pre>
    return(
      <div id="stats">
      <h2>Движение средств <PaysSvg /></h2>
        <div className="dtable">
          <div className="drow dhead"><div className="dcell"><h4>Имя</h4></div><div className="dcell"><h4>Дата</h4></div><div className="dcell"><h4>Сумма</h4></div></div>
          {data.pays.map(item => (
              <Media queries={{small: '(max-width:992px)'}}>
              {(size) => size.small ? (
                <div className={login == item.user ? "drow active" : "drow"} key={item.id} onClick={() => loadUser(`/user/${item.user}`)}><div className={item.login.isOnline ? "dcell online" : "dcell"}>{item.user.slice(0,9)}{item.user.length > 9 && '..'}</div><div className="dcell">{format(item.created, 'dd.LL.yyyy')}</div><div className={item.type ? 'dcell grn' : 'dcell red'}>{item.amount > 0 && <PlusSvg />} {item.amount} &#8381;</div></div>
              ) : (
                <LightSpeed right cascade>
                  <div className={login == item.user ? "drow active" : "drow"} key={item.id} onClick={() => loadUser(`/stats/user/${item.user}`)}><div className={item.login.isOnline ? "dcell online" : "dcell"}>{item.user.slice(0,9)}{item.user.length > 9 && '..'}</div><div className="dcell">{format(item.created, 'dd.LL.yyyy')}</div><div className={item.type ? 'dcell grn' : 'dcell red'}>{item.amount > 0 && <PlusSvg />} {item.amount} &#8381;</div></div>
                </LightSpeed>
              )}
              </Media>
          ))}
        </div>
      </div>
    )
}
export default memo(PaysTbl)