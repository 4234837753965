import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import {setContext} from 'apollo-link-context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'


const httpURI = "https://vzp.su:4000/api"
const wsURI = "wss://vzp.su:4000/api"

const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'Apollo-Require-Preflight': 'true',
        Authorization: localStorage.getItem('token') || '',
        Accept: 'application/json'
      }
    }
})
const httpLink = new HttpLink({
  uri: httpURI,
  credentials: 'same-origin'
})

const wsLink = new GraphQLWsLink(createClient({
  url: wsURI,
  connectionParams: {
    authToken: "token"
  }
}))

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(httpLink)
)

const cache = new InMemoryCache()

const memoryCache = new InMemoryCache({
  possibleTypes: {
    xyz: ["abc", "def"],
  },
}).restore(cache)

const client = new ApolloClient({
    cache: memoryCache,
    link: splitLink,
    resolvers: {},
    connectToDevTools: true,
    cors: {
      origin: true,
      credentials: true, // true if you need cookies/authentication
      methods: ['GET', 'POST', 'OPTIONS']
    }
})

export default client
