import React, { useState, useRef } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import {useQuery, gql} from '@apollo/client'

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.defaults.font.size = 14
ChartJS.defaults.font.family = '"Podkova", serif'

const GET_CHART = gql`
      query {
        tickets {
          user
          created
        }
      }
`;


const options = {
  responsive: true,
  plugins: {
    legend: {position: 'bottom'}
  }
}

const TicketsFund = () => {
  const [ticketsNum, setTicketsNum] = useState(0)
  const {data, loading, error} = useQuery(GET_CHART)
  if (loading) return <div className="loading" />
  if (error) return <pre>{error.message}</pre>
  const {tickets} = data
//  if(tickets.length > 0) setTicketsNum(tickets.length)

  const chartData = {
    labels: ['Собрано', 'Осталось'],
    datasets: 
      [{
        label: [],
        data: [tickets.length, (100 - tickets.length)],
        backgroundColor: ['rgba(5,255,81,.7)', 'rgba(48,56,145,.5)'],
        borderColor: ['rgba(35,73,118,1)', 'rgba(35,73,118,1)'],
        borderWidth: 2
      }]
    }

    return (
      <div id="tickets-fund">
          <Pie data={chartData} options={options} />
      </div>    
    )
}
export default TicketsFund