import React, { useState, useRef } from 'react'
import {useMutation, gql} from '@apollo/client'
import { Navigate } from 'react-router-dom'
import { addMinutes } from 'date-fns'

import Bounce from 'react-reveal/Bounce'


const NEW_CHAT = gql`
  mutation newChat($created: DateTime, $deleted: DateTime, $user: String, $message: String, $private: ID) {
    newChat(created: $created, deleted: $deleted, user: $user, message: $message, private: $private) {
      id
      created
      message
      deleted
      user
      private
      login {
        gender
        created
        lastdate
        tickets{
          id
        }
      }
    }
  }`;



function ChatForm({thisClass}){
    const login = localStorage.getItem('login')
    const [emptyMessage, setEmptyMessage] = useState(false)
    const [nextMessage, setNextMessage] = useState(false)
    const [appendMessage, setAppendMessage] = useState(false)
    const token = localStorage.getItem('token')
    const userLogin = login ? login : "Anon"
    const refMessage = useRef()
    const refDeleted = useRef() 

    function sendChat(event){
          event.preventDefault()
          let user = userLogin,
              privateMS = 0,
              message = refMessage.current.value,
              datenow = new Date(),
              created = addMinutes(datenow, 180),
              deletedMS = refDeleted.current.value,
              deleted
          deletedMS > 0 ? deleted = addMinutes(created, deletedMS) : deleted = ""
          if(message) newChat({ variables: {created, deleted, user, message, private: privateMS}}) 
          else {
            setEmptyMessage(true)
            setTimeout(() => setEmptyMessage(false), 3000)
          }
      }
    
   const [newChat, { loading, errors, data }] = useMutation(NEW_CHAT, {
          onCompleted: data => {
              refMessage.current.value = ""
              refDeleted.current.value = 0
              setAppendMessage(true)
              setTimeout(() => setAppendMessage(false), 3000)
          },
          onError: (error) => {
              setNextMessage(true)
              setTimeout(() => setNextMessage(false), 3000)
              console.log("========"+error)
          }
    })
    
  //  if(appendMessage) return <Navigate to={window.location.pathname} />
    return(
      <div id="chat-form" className={thisClass}>
      <form onSubmit={sendChat} onClick={() => {
            setEmptyMessage(false)
            setNextMessage(false)
            setAppendMessage(false)
          }
        }>
        <Bounce right opposite when={emptyMessage}><span className="error-param">Вы не ввели сообщение</span></Bounce>
        <Bounce right opposite when={nextMessage}><span className="error-param">Не прошло 10 мин. с предыдущего сообщения</span></Bounce>
        <Bounce right opposite when={appendMessage}><span className="error-param">Сообщение добавлено в Чат</span></Bounce>
        <textarea ref={refMessage} placeholder="Здесь вы можете написать сообщение, которое будет выведено в Чат и станет доступно всем посетителям сайта. Также через Чат вы можете задать интересующий вас вопрос администрации Фонда и получить ответ."></textarea>
        <div className="form-field-wrap">
            <select ref={refDeleted} id="chat-select" name="deletedMS"><option value="0">самостоятельно</option><option value="5">через 5 минут</option><option value="30">через 30 минут</option><option value="60">через 1 час</option><option value="1440">через 1 день</option><option value="4320">через 3 дня</option></select>
            <label htmlFor="chat-select">автоудаление</label>
        </div>
        <button>Отправить</button>
      </form>
      </div> 
    )
}
export default ChatForm