import React, {lazy, Suspense, useState, useEffect} from 'react'
import Media from 'react-media'
import {Route, Routes, NavLink, useNavigate} from 'react-router-dom'
import {useApolloClient, useQuery, useMutation, gql} from '@apollo/client'
import ScrollToTop from "./comps/ScrollToTop"
import {loadErrorMessages, loadDevMessages} from "@apollo/client/dev"
import { addMinutes } from 'date-fns'

import {ReactComponent as OnlinersSvg} from './lib/svg/onliners.svg'
import {ReactComponent as LogoSvg} from './lib/svg/logo.svg'
import TicketsList from './comps/TicketsList' 
import TicketsFund from './comps/TicketsFund' 
import UserLogin from './comps/UserLogin'
import UserInfo from './comps/UserInfo'
import UserOnline from './comps/UserOnline'
import UserLogout from './comps/UserLogout'
import Home from './static/Home'
import './lib/css/style.css'

const User = lazy(() => import('./static/User'))
const Stats = lazy(() => import('./static/Stats'))
const Register = lazy(() => import('./static/Register')) 
const Reset = lazy(() => import('./static/Reset')) 
const About = lazy(() => import('./static/About'))
const Rules = lazy(() => import('./static/Rules'))
const Locations = lazy(() => import('./static/Locations'))


loadDevMessages()
loadErrorMessages()

const GET_ONLINE = gql`
  query onlineUsers{
    onlineUsers{
      login
      created
      online
    }
}`;

const NEW_ONLINE = gql`
mutation onliner($login: String, $created: DateTime, $online: Int) {
  onliner(login: $login, created: $created, online: $online){
    login
    created
    online
  }
}`;


const App = () => {
  const dataUser = {
    isLoggedIn: !!localStorage.getItem('token'),
    token: localStorage.getItem('token'),
    login: localStorage.getItem('login')
  }
  const [userLogin, setUserLogin] = useState(dataUser.login)
  const [online, setOnline] = useState(userLogin || 'Anon')

  const client = useApolloClient()
  const navigate = useNavigate()

  const onLogout = event => {
    event.preventDefault()
    localStorage.removeItem('token')
    localStorage.removeItem('login')
    client.resetStore()
  //  client.cache.modify({ data: { isLoggedIn: false, token: null, login: null } })
    setUserLogin(false)
    navigate('/')
  }
  const onLogin = (login, token) => {
    localStorage.setItem('token', token)
    localStorage.setItem('login', login) 
  //  client.cache.modify({ data: { isLoggedIn: true, token: token, login: login } })
    setUserLogin(login)
    navigate(`/user/${login}`)
  }
  const onRegister = (login) => {
    setUserLogin(login)
    navigate(`/user/${login}`)
  }


  const [newOnline] = useMutation(NEW_ONLINE, {
    onCompleted: data => {
        console.log(data.onliner)
      //  console.log("======onliner======")
        setOnline(data.onliner.login)
    },
    onError: error => {
        console.log(error)
    }
  })

  function sendOnline(user){
    let datenow = new Date(),
        created = addMinutes(datenow, 180),
        online = 1
        console.log({user})  
        newOnline({ variables: {created, online, login: user}})
  }

  const [onliners, setOnliners] = useState([])
  const {data} = useQuery(GET_ONLINE, {
    onCompleted: () => {
        const {onlineUsers} = data
        console.log(onlineUsers)
      //  console.log("''''''''onlineUsers'''''''''''")
        setOnliners(onlineUsers)
    },
    onErrors: (error) => {
        console.log(error)
    }
  })

  useEffect(() => {
    const timer = setInterval(() => {
      client.refetchQueries({include: [GET_ONLINE]})
    }, 60000)
    return () => clearInterval(timer)
  })
  
  useEffect(() => {
    sendOnline(online)
  //  setTimeout(() => delOnline(), 30000)
  }, [])  

  useEffect(() => {
    setOnliners(onliners)
  }, [onliners])  

  let navHide = () => setNavActive(false)

  const [navActive, setNavActive] = useState(false)

  useEffect(() => {
      if(navActive === true) setTimeout(() => navHide(), 30000)
  }, [navActive])

  return (
  <>
    <Media queries={{small: '(max-width:992px)'}}>
    {(size) => size.small ? (
      <header>
      <logo onClick={navHide}>
          <figure><NavLink to="/"><LogoSvg /></NavLink></figure>
          <figcaption><NavLink to="/"><b>Взаимопомощь</b> <span>фонд обмена деньгами</span></NavLink></figcaption>
      </logo>
      <nav className={navActive && "active"}>
          <NavLink to="/static/fund" onClick={navHide}>Фонд</NavLink>
          {userLogin ? <NavLink to={`/user/${userLogin}`} onClick={navHide}>Профиль</NavLink> : <NavLink to="/user/register" onClick={navHide}>Регистрация</NavLink>}
          <NavLink to="/static/rules" onClick={navHide} classname={(isActive) => isActive && 'active'}>Правила</NavLink>
          <NavLink to={`/user/Webmaster`} onClick={navHide}>Контакты</NavLink>
          {userLogin ? <div id="logout"><UserInfo login={userLogin} /> <UserLogout onLogout={onLogout} /></div> : <UserLogin onLogin={onLogin} />}
          {<UserOnline data={onliners} />}
      </nav>
      <div className={navActive ? "toggler toggler-open" : "toggler"} onClick={() => setNavActive(!navActive)}><span></span><span></span><span></span></div>
      </header>
    ) : (
      <header>
      <logo>
          <figure><NavLink to="/"><LogoSvg /></NavLink></figure>
          <figcaption><NavLink to="/"><b>Взаимопомощь</b> <span>фонд обмена деньгами</span></NavLink></figcaption>
      </logo>
      <div className="onliners">
            <div><OnlinersSvg /></div>
            <div>Online: <b>{onliners.length}</b></div>
      </div>
      {userLogin ? <div id="logout"><UserInfo login={userLogin} /> <UserLogout onLogout={onLogout} /></div> : <UserLogin onLogin={onLogin} />}
      <nav className={navActive && "active"}>
          <NavLink to="/static/fund">Фонд</NavLink>
          {userLogin ? <NavLink to={`/user/${userLogin}`}>Профиль</NavLink> : <NavLink to="/user/register">Регистрация</NavLink>}
          <NavLink to="/static/rules" classname={(isActive) => isActive && 'active'}>Правила</NavLink>
          <NavLink to={`/user/Webmaster`}>Контакты</NavLink>
      </nav>
      </header>
    )}
    </Media>
    <section className="content">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/user/reset/:reset" element={<Suspense fallback={<p><div className="loading" /></p>}><Reset action={onRegister} /></Suspense>} />
            <Route path="/user/reset" element={<Suspense fallback={<p><div className="loading" /></p>}><Reset /></Suspense>} />
            <Route path="/user/:user" element={<Suspense fallback={<p><div className="loading" /></p>}><User /></Suspense>} />
            <Route path="/user/register" element={<Suspense fallback={<p><div className="loading" /></p>}><Register action={onRegister} /></Suspense>} />
            <Route path="/stats/user/:user" element={<Suspense fallback={<p><div className="loading" /></p>}><Stats /></Suspense>} />
            <Route path="/static/locations" element={<Suspense fallback={<p><div className="loading" /></p>}><Locations /></Suspense>} />
            <Route path="/static/fund" element={<Suspense fallback={<p><div className="loading" /></p>}><About /></Suspense>} />
            <Route path="/static/rules" element={<Suspense fallback={<p><div className="loading" /></p>}><Rules /></Suspense>} />
        </Routes> 
        <Locations />
        <TicketsFund />
        <TicketsList />  
    </section>
    <footer>
      &copy; 2024 Взаимопомощь - фонд обмена деньгами.
    </footer>
    <ScrollToTop />
  </>
  )
}

export default App
