import React, {memo} from 'react'
import {useQuery, gql} from '@apollo/client'
import New from './New' 

const GET_NEWS = gql`
      query News {
        news {
          created
          title
          description
        }
      }
`;

function News({thisClass}){
    const {data, loading, error} = useQuery(GET_NEWS)
    if (loading) return <div className="loading" />
    if (error) return <pre>{error.message}</pre>
    return(
    <div id="news" className={thisClass}>
        {data.news.map(item => (
          <New key={item.created} title={item.title} created={item.created} description={item.description} /> 
        ))}
    </div>
    )
}
export default memo(News)