import {memo} from 'react'

import {useQuery, gql} from '@apollo/client'
import { useNavigate } from "react-router-dom"
import {format, addMinutes, intervalToDuration} from 'date-fns'

import Slide from 'react-reveal/Slide'

import {ReactComponent as JoinedSvg} from '../lib/svg/joined.svg'
import {ReactComponent as LoginSvg} from '../lib/svg/login.svg'
import {ReactComponent as BoySvg} from '../lib/svg/avatars/boy.svg'
import {ReactComponent as GirlSvg} from '../lib/svg/avatars/girl.svg'

import {ReactComponent as NewbeSvg} from '../lib/svg/newbe.svg'


const GET_NEWBE = gql`
      query {
        newbe {
          id
          created
          lastdate
          gender
          login
        }
      }
`;

const createdDate = (date) => {
    const created = new Intl.DateTimeFormat('ru', {
        year: 'numeric', month: 'long', day: 'numeric'
    }).format((new Date(date)).getTime()) 
    return created  
}

const timeSince = (date) => {
    const interval = intervalToDuration({
      start: new Date(date),
      end: addMinutes(new Date(), 180)
    })
    if(interval.months){
      return interval.months + " мес" 
    }
    else if(interval.days){
      return interval.days + " дн"   
    }
    else if(interval.hours){
      return interval.hours + " час"   
    }
    else if(interval.minutes){
      return interval.minutes + " мин"   
   }
  }
function Newbe({thisClass}){
    const loadUser = useNavigate()
    const {data, loading, error} = useQuery(GET_NEWBE)
    if (loading) return <div className="loading" />
    if (error) return <pre>{error.message}</pre>
    return(
    <div id="newbe" className={thisClass}>
        <h2>Новые пользователи <NewbeSvg /></h2>
        <section>
        <Slide left cascade fraction="1">
            <ul className="msg-wrap">
            {data.newbe.map(item => (
                <li key={item._id} className="msg" onClick={() => loadUser(`/user/${item.login}`)}>
                    <figure>
                        {item.gender == 1 ? <BoySvg /> : <GirlSvg />}
                        <div>
                            <ul className="svg-wrap">
                                <li><JoinedSvg /> {createdDate(item.created)}</li>
                            </ul>
                            <figcaption>{item.login.slice(0,9)}{item.login.length > 9 && '..'} <span><LoginSvg /> {timeSince(item.lastdate)}</span></figcaption>
                        </div>
                    </figure> 
                </li>
            ))}
            </ul>
        </Slide>    
        </section>
    </div>  
    )
}
export default memo(Newbe)