import React, {memo, useState, useEffect} from 'react'
import { gql, useQuery, useSubscription, useApolloClient } from '@apollo/client'

import {ReactComponent as BoySvg} from '../lib/svg/avatars/boy.svg'
import {ReactComponent as GirlSvg} from '../lib/svg/avatars/girl.svg'

import {ReactComponent as JoinedSvg} from '../lib/svg/joined.svg'
import {ReactComponent as TicketsSvg} from '../lib/svg/tickets.svg'
import {ReactComponent as WinnerSvg} from '../lib/svg/winner.svg'
import {ReactComponent as LocationSvg} from '../lib/svg/location.svg'
import {ReactComponent as EmailSvg} from '../lib/svg/email.svg'
import {ReactComponent as LoginSvg} from '../lib/svg/login.svg'
import {ReactComponent as BalanceSvg} from '../lib/svg/balance.svg'
import {ReactComponent as ChatedSvg}  from '../lib/svg/chated.svg'

const GET_USER = gql`
query User($login: String!) {
  user(login: $login) {
    lastdate
    location
    gender
    avatar
    balance
    tickets{
      id
    }
    chated{
      id
    }
  }
}`;

const SUB_CHAT = gql`
subscription {
  subChat {
    message 
  }
}`;

const UserInfo = props => {
  const client = useApolloClient()
  const {login} = props
 // var {loading, error, data} = useQuery(GET_USER, { variables: { login }})
  const {data, loading, error} = useQuery(GET_USER, { variables: { login }})
  const {data: subscriptionData, loading: subscriptionLoading} =  useSubscription(SUB_CHAT)

  useEffect(() => {
    if(subscriptionData) {
      console.log("SUB")
      client.refetchQueries({include: [GET_USER]})
    }
  }, [subscriptionData]);

  if(loading) return <div className="loading" />
  let lastdate = new Date(), location="Локация", gender=1, avatar="", tickets=0, balance=0, chated=0
  try {
    for (const [key, val] of Object.entries(data.user)) {
          if(key === "avatar") avatar = val
          if(key === "lastdate") lastdate = val
          if(key === "balance") balance = val
          if(key === "location") location = val
          if(key === "gender") gender = val
          if(key === "tickets") tickets = val.length
          if(key === "chated") chated = val.length
    }
  } catch (error) {
    console.log("Произошла ошибка:", error.message)
  }

  return (
        <div id="user-info">
          {gender == 1 ? <BoySvg /> : <GirlSvg />}
          <ul className="svg-wrap">
              <li>Привет, {login}!</li>
              <li><TicketsSvg /> {tickets}</li>
              <li><WinnerSvg /> 0</li>
              <li><ChatedSvg /> {chated}</li>
              <li><BalanceSvg /> {new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(balance)}</li>
          </ul>
        </div>
  )
}

export default memo(UserInfo)