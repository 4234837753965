import React, {memo, useState} from 'react'
import {intervalToDuration} from 'date-fns'
import {ReactComponent as PlusSvg} from '../lib/svg/plus.svg'


const timeSince = (date) => {
  const interval = intervalToDuration({
    start: new Date(date),
    end: new Date()
  })

  if(interval.months){
    return interval.months + " мес" 
  }
  else if(interval.days){
    return interval.days + " дн"   
  }
  else if(interval.hours){
    return interval.hours + " час"   
  }
  else if(interval.minutes){
    return interval.minutes + " мин"   
 }
}


const New = ({title, description, created}) => {
    const [active, setActive] = useState(true)
    return <aside key={created}><h3 onClick={() => setActive(v => !v)}><PlusSvg className={active && 'active'} /> {title} <span>..{timeSince(created)} назад</span></h3><p className={active && 'active'}>{description}</p></aside>
}
export default memo(New)