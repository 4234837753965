import {memo} from 'react'
import {format} from 'date-fns'
import { useNavigate } from "react-router-dom"

function ChartDetal(props){
    const loadUser = useNavigate()
    let title = ""
    props.data[0][0] != "user" ? title = "Взносы пользователей" : title = "Регистрация пользователей"
    return(
        <div className="detail">
            <h2>{title}</h2>
            <ul>
                {props.data.map((prop) => <li key={prop[1]} onClick={() => loadUser(`/user/${prop[2]}`)}>{format(prop[1], 'yyyy.MM.dd')} <span>{prop[2].slice(0,16)}{prop[2].length > 16 && '..'}</span></li>)}
            </ul>
        </div>
    )
}
export default memo(ChartDetal)