import React, {memo} from 'react'
import { useNavigate } from "react-router-dom"
import {useQuery, gql} from '@apollo/client'
import {format} from 'date-fns'

import Zoom from 'react-reveal/Zoom'

import {ReactComponent as TicketSvg} from '../lib/svg/ticket.svg'

const GET_TICKETS = gql`
      query {
        tickets {
          id
          created
          user
        }
      }
`;


const TicketsList = () => {
    const loadUser = useNavigate()
    const {data, loading, error} = useQuery(GET_TICKETS)
    if (loading) return <div className="loading" />
    if (error) return <pre>{error.message}</pre>
    return(
      <div id="tickets-list" className="main-list">
       <Zoom top cascade fraction=".4"> 
       <ul>
        {data.tickets.map(item => (
            <li key={item._id} onClick={() => loadUser(`/user/${item.user}`)}><TicketSvg /><aside><span className="date">{format(item.created, 'dd.MM.yyyy')}</span>{item.user.slice(0,9)}{item.user.length > 9 && '..'}<span className="id">{item.id.slice(-11)}</span></aside></li>
        ))}
        </ul>
        </Zoom>
      </div>
    )
}
export default memo(TicketsList)