import {memo} from 'react'
import {ReactComponent as OnlinersSvg} from '../lib/svg/onliners.svg'

const UserOnline = (props) => {
  return (
        <div className="onliners">
            <div><OnlinersSvg /></div>
            <div>Online: <b>{props.data.length}</b></div>
        </div>
  )
}

export default UserOnline
//<UserOnline data={onliners} />