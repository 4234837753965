import React, { memo, useState, useReducer, useRef } from 'react'
import { useMutation, useApolloClient, gql } from '@apollo/client'
import {Link} from "react-router-dom"

import Fade from 'react-reveal/Fade'


const LOGIN_USER = gql`
  mutation signIn($login: String, $password: String) {
    signIn(login: $login, password: $password){
      hash
      login
    }
  }
`;

const UserLogin = props => {
  const client = useApolloClient()
  const [userLogin, setUserLogin] = useState()
  const [loginError, reduceError] = useReducer(count => ++count, 0)
  const [errorDialog, setErrorDialog] = useState(false)
  const refLogin = useRef()
  const refPassword = useRef() 

  function sendLogin(event){
        event.preventDefault()
        signIn({ variables: {login: refLogin.current.value, password: refPassword.current.value}}) 
    }
  
 const [signIn, { loading, errors, data }] = useMutation(LOGIN_USER, {
        onCompleted: data => {
            setUserLogin(data.signIn.login)
            props.onLogin(data.signIn.login, data.signIn.hash)
            errorDialog(false)
        },
        onError: (error) => {
          if(error.message){
            setUserLogin(false)
            reduceError(1)
            setErrorDialog(true)
            setTimeout(() => setErrorDialog(false), 2000)
            console.log(error.message)
          }
          else{
            setUserLogin(data.signIn)
            props.onLogin(refLogin.current.value, data.signIn)
            console.log("ok")
          }
        }
  })
 // if(userLogin) return <Navigate to="/" />
  return (
    <div>
    {userLogin && <h4>Авторизация пройдена!</h4>}
    {!userLogin && <form onSubmit={sendLogin} onClick={() => setErrorDialog(false)}>
        <Fade right opposite when={errorDialog}><span className="error">Неверный логин ..или пароль</span></Fade>
        <input
          required
          ref={refLogin}
          id="login-top" 
          type="text"
          name="login"
          placeholder="Логин или email" 

          />
        <input
          required
          ref={refPassword}
          id="password-top" 
          type="password"
          name="password"
          placeholder="Пароль" 

          />
          {loginError < 3 && <button type="submit">вход</button> }
          {loginError >= 3 && <Link to="/user/reset">восстановить<br />пароль</Link> }
    </form>}
    </div>
  )
}

export default memo(UserLogin)
