import React from 'react'
import {useMutation, gql} from '@apollo/client'

const DEL_CHAT = gql`
  mutation delChat($id: ID!) {
    delChat(id: $id)
  }
`;

export default function ChatDel(props) {
    const [delChat] = useMutation(DEL_CHAT, {
        variables: { id: props.del }
      })
    return <li onClick={delChat}>удалить</li>
  }