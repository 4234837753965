import React, {useEffect} from 'react'
import Media from 'react-media'

import PaysTbl from '../comps/PaysTbl' 
import Chat from '../comps/Chat' 
import ChatForm from '../comps/ChatForm' 
import Newbe from '../comps/Newbe' 
import News from '../comps/News' 
import Chart from '../comps/Chart' 


function Home(){
      useEffect(() => {document.title = 'Фонд ВзаимоПомощь'}, [])
      return (
        <>
        <Chart /> 
        <PaysTbl />
        <News thisClass="news-home" />
        <Chat thisClass="chat-home" />

        <Media queries={{small: '(max-width:992px)'}}>
        {(size) => size.small ? ( 
          <>    
            <ChatForm thisClass="chat-form-home" />
            <Newbe thisClass="newbe-home" />
          </> 
        ) : (
          <> 
            <Newbe thisClass="newbe-home" />
            <ChatForm thisClass="chat-form-home" />
          </> 
        )}    
        </Media>
        
        </>
    )
}
export default Home

